//import vue
import Vue from 'vue'

//import vue router
//import VueRouter from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

//Vue.use(VueRouter)

//import axios
import axios from 'axios'
//default base URL / EndPoint API
//axios.defaults.baseURL = "https://backenddb.arrahmanic.org"
axios.defaults.baseURL = "https://webbackend.arrahmanic.org"

const routes = [{
            path: '/',
            name: 'home',
            component: () =>
                import( /* webpackChunkName: "Home" */ "@/views/home/Index"),
        },
        {
            path: '/post',
            name: 'post',
            component: () =>
                import( /* webpackChunkName: "PostIndex" */ "@/views/post/Index"),
        },
        {
            path: '/tag/:slug',
            name: 'detail_tag',
            component: () =>
                import( /* webpackChunkName: "TagShow" */ "@/views/tag/Show")
        },
        {
            path: '/category/:slug',
            name: 'detail_category',
            component: () =>
                import( /* webpackChunkName: "CategoryShow" */ "@/views/category/Show")
        },
        {
            path: '/event',
            name: 'event',
            component: () =>
                import( /* webpackChunkName: "EventIndex" */ "@/views/event/Index")
        },
        {
            path: '/event/:slug',
            name: 'detail_event',
            component: () =>
                import( /* webpackChunkName: "EventShow" */ "@/views/event/Show")
        },
        {
            path: '/photo',
            name: 'photo',
            component: () =>
                import( /* webpackChunkName: "PhotoIndex" */ "@/views/photo/Index")
        },
        {
            path: '/video',
            name: 'video',
            component: () =>
                import( /* webpackChunkName: "VideoIndex" */ "@/views/video/Index")
        },
        {
            path: '/contact',
            name: 'contact',
            component: () =>
                import( /* webpackChunkName: "ContactIndex" */ "@/views/contact/Index")
        },
        {
            path: '/tentangkami',
            name: 'tentangkami',
            component: () =>
                import( /* webpackChunkName: "TentangkamiIndex" */ "@/views/tentangkami/Index")
        },
        {
            path: '/pendidikan',
            name: 'pendidikan',
            component: () =>
                import( /* webpackChunkName: "Pendidikan" */ "@/views/pendidikan/Index")
        },
        {
            path: '/sosial',
            name: 'sosial',
            component: () =>
                import( /* webpackChunkName: "Sosial" */ "@/views/sosial/Index")
        },
        {
            path: '/:slug',
            name: 'detail_post',
            component: () =>
                import( /* webpackChunkName: "PostShow" */ "@/views/post/Show")
        },
        {
            path: '/pengumuman',
            name: 'pengumuman_mhq',
            component: () =>
                import( /* webpackChunkName: "PengumumanMHQ" */ "@/views/pengumuman/Mhq")
        },
    ]
    
//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes,
})

//define route for handle authentication
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //cek nilai dari getters isLoggedIn di module auth
        if (store.getters['auth/isLoggedIn']) {
        next()
        return
      }
      next('/login')
    } else {
      next()
    }
})

export default router
